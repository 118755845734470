<template>
  <b-card>
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1 d-none"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="my-lg-4 mx-lg-1">
      <b-form-group label-for="production-source">
        <b-form-select
          id="production-period"
          v-model="period"
          size="lg"
          @change="updateFilters"
        >
          <b-form-select-option
            v-for="option in constants.WEEKS"
            :key="option.start_at"
            :value="option.start_at"
          >
            Semaine {{ option.week_of_year }}
            ( du
            {{ $dayjs(option.start_at).format('DD.MM.YYYY') }} au
            {{ $dayjs(option.end_at).format('DD.MM.YYYY') }}
            <span v-if="option.is_current == true">- cette semaine</span>
            )
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>

    <div v-if="productions_batches.length > 0" class="mb-5">
      <h4>Planifications</h4>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-left">Fabrication</th>
            <th class="text-center">Statut</th>
            <th class="text-right">Taches</th>
            <th class="text-right">Quantités</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="batch in productions_batches">
            <tr :key="batch.id">
              <td class="text-center">
                <font-awesome-icon icon="clipboard-list" size="lg" />
              </td>
              <td class="text-left">
                <h4>
                  <router-link :to="`/productions/batches/${batch.id}`">
                    Le {{ $dayjs(batch.due_at).format('DD.MM.YYYY') }}
                  </router-link>
                </h4>
              </td>
              <td class="text-center">
                <span class="text-nowrap">
                  {{ $t(`PRODUCTION.${batch.status}`) }}
                </span>
              </td>
              <td class="text-right">{{ batch.tasks_count }}</td>
              <td class="text-right">{{ batch.quantities }}</td>
              <td class="text-right">
                <button
                  class="btn btn-warning btn-sm"
                  @click.prevent="deleteBatch(batch.id)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div>
      <h4>Tâches</h4>
      <b-overlay :show="loading" rounded="sm">
        <div class="row mb-2">
          <div class="col-md-8">
            <button
              v-if="productions_tasks.length > 0"
              :disabled="tasks.length === 0"
              class="btn btn-success"
              @click.prevent="
                displayPlanificationForm = !displayPlanificationForm
              "
            >
              Planifier ({{ tasks.length }})
            </button>
          </div>
          <div class="col-md-3 px-0">
            <b-form-select
              v-model="filters.source"
              placeholder="Type"
              @change="init()"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Tous les types
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in [
                  'USER_SELECTION',
                  'INTERNAL',
                  'MEAL_DELIVERY',
                ]"
                :key="option"
                :value="option"
              >
                {{ $t(`PRODUCTION.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-md-1 text-right px-0">
            <button
              class="btn btn-info btn-icon mr-1"
              @click.prevent="displayForm = !displayForm"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>
        </div>
        <div v-if="displayForm == true" class="m-lg-5 mb-5">
          <h3>Créer une tâche</h3>
          <production-task-form
            :creation="true"
            :display.sync="displayForm"
            :week="getPeriod(period)"
            @created="onCreate"
          />
        </div>
        <div
          v-if="displayPlanificationForm == true && tasks.length > 0"
          class="border m-3 p-2"
        >
          <div>
            <h4>
              Ajouter une quantité suplémentaire à chaque tâche lors de
              l'association.
            </h4>
            <b-form-input
              id="input-title"
              v-model="addQuantity"
              type="number"
              min="0"
              placeholder="Ajouter quantité"
            />
          </div>
          <div class="mt-3 mb-4">
            <h4 class="mb-2">Créer une nouvelle planification</h4>
            <production-batch-form
              :creation="true"
              :due-at="filters.start_at"
              :add-quantity.sync="addQuantity"
              :tasks="tasks"
              :display.sync="displayPlanificationForm"
              @created="createdBatch"
            />
          </div>
          <div v-if="productions_batches.length > 0" class="mt-3">
            <h4 class="mb-2">Ou associer à une planification existante</h4>
            <table class="table table-hover">
              <tbody>
                <template v-for="batch in productions_batches">
                  <tr :key="batch.id">
                    <td class="text-center">
                      <font-awesome-icon icon="clipboard-list" size="lg" />
                    </td>
                    <td class="text-left">
                      <h4>
                        Le {{ $dayjs(batch.due_at).format('DD.MM.YYYY') }}
                      </h4>
                    </td>
                    <td class="text-center">
                      <span class="text-nowrap">
                        {{ $t(`PRODUCTION.${batch.status}`) }}
                      </span>
                    </td>
                    <td class="text-right">{{ batch.tasks_count }}</td>
                    <td class="text-right">{{ batch.quantities }}</td>
                    <td class="text-right">
                      <button
                        class="btn btn-primary btn-sm"
                        @click.prevent="assocToBatch(batch.id)"
                      >
                        Sélectionner
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-center">
                <b-form-checkbox
                  v-model="selectAll"
                  switch
                  @change="toggleSelectTasks()"
                />
              </th>
              <th class="px-1">Type</th>
              <th class="px-1">Fabrication</th>
              <th class="text-center px-1">Statut</th>
              <th class="text-center px-1">Quantité</th>
              <th class="text-right px-1">Livraison</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(task, key) in productions_tasks">
              <tr
                :key="task.id"
                :class="{ 'bg-light': tasks.includes(task.id) == true }"
              >
                <td class="text-center">
                  <font-awesome-icon
                    v-if="task.production_batch_id"
                    icon="thumbtack"
                    size="lg"
                  />
                  <b-form-checkbox
                    v-if="
                      task.production_batch_id === null &&
                      task.status === 'PENDING'
                    "
                    :id="`checkbox-${key}`"
                    v-model="tasks"
                    :value="task.id"
                    switch
                  />
                </td>
                <td class="px-1">
                  <small class="badge badge-light text-info">
                    {{ $t(`PRODUCTION.${task.source}`) }}
                  </small>
                </td>
                <td class="px-1">
                  <h5>
                    <router-link :to="`/productions/tasks/${task.id}`">
                      {{ task.recipe.label }}
                    </router-link>
                  </h5>
                </td>
                <td class="text-center px-1">
                  <router-link
                    v-if="task.production_batch_id"
                    :to="{
                      name: 'productionBatch',
                      params: { id: task.production_batch_id },
                    }"
                  >
                    <span class="text-nowrap">
                      {{ $t(`PRODUCTION.${task.status}`) }}
                    </span>
                  </router-link>
                  <span v-else class="text-nowrap">
                    {{ $t(`PRODUCTION.${task.status}`) }}
                  </span>
                </td>
                <td class="text-center px-1">{{ task.quantity }}</td>
                <td class="text-right px-1">
                  {{ $dayjs(task.due_at).format('DD.MM.YYYY') }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </b-overlay>
    </div>
    <p
      v-if="productions_tasks.length == 0 && productions_batches.length == 0"
      class="text-center text-muted"
    >
      <em>Aucune tâche pour l’instant</em>
    </p>
    <!-- <pre>{{ tasks }}</pre> -->
    <!-- <pre>{{ productions_tasks }}</pre> -->
    <!-- <pre>{{ productions_batches }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import ProductionTaskForm from '@/components/ProductionTaskForm.vue'
import ProductionBatchForm from '@/components/ProductionBatchForm.vue'

const productionsTasksFields =
  'results{id,status,source,production_batch_id,quantity,due_at,recipe{label}},meta'

const productionsBatchesFields =
  'results{id,status,tasks_count,quantities,due_at},meta'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    ProductionTaskForm,
    ProductionBatchForm,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      displayFilters: true,
      displayForm: false,
      displayPlanificationForm: false,
      addQuantity: 0,
      selectAll: false,
      constants: {},
      tasks: [],
      period: 0,
      productions_batches: [],
      productions_tasks: [],
      pagination_batches: { total: 0 },
      pagination_tasks: { total: 0 },
      filters: {
        page: 1,
        source: null,
        status: ['PENDING', 'ASSIGNED'],
        per_page: 999,
        start_at: null,
        end_at: null,
      },
    }
  },
  computed: {
    title() {
      if (this.period && this.constants.WEEKS) {
        const week = this.getPeriod(this.period)
        return `Semaine ${week.week_of_year} - ${this.$dayjs(
          this.period
        ).format('YYYY')}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
    period(value) {
      const week = this.getPeriod(value)
      if (week) {
        this.filters.start_at = week.start_at
        this.filters.end_at = week.end_at
        localStorage.LEONETMARCEL_PERIOD = value
      }
    },
    tasks(value) {
      if (
        value.length !==
        this.productions_tasks.filter(
          (task) => task.production_batch_id === null
        ).length
      ) {
        this.selectAll = false
      }
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'WEEKS,CURRENT_WEEK' },
      })
      this.constants = respConstants.data

      if (localStorage.LEONETMARCEL_PERIOD) {
        this.period = localStorage.LEONETMARCEL_PERIOD
      } else {
        this.period = this.constants.CURRENT_WEEK.start_at
      }

      const week = this.getPeriod(this.period)
      this.filters.start_at = week.start_at
      this.filters.end_at = week.end_at

      const resp2 = await this.$http.get('/productions/batches/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': productionsBatchesFields,
        },
      })
      this.productions_batches = resp2.data.results
      this.pagination_batches = resp2.data.meta

      const resp = await this.$http.get('/productions/tasks/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': productionsTasksFields,
        },
      })
      this.productions_tasks = resp.data.results
      this.pagination_tasks = resp.data.meta

      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate() {
      this.displayForm = false
      this.init()
    },
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.filters, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    async createdBatch() {
      this.tasks = []
      this.init()
      this.addQuantity = 0
    },
    async assocToBatch(batchId) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const payload = { tasks: this.tasks }
      if (this.addQuantity >= 0) {
        payload.add_quantity = this.addQuantity
      }
      const resp = await this.$http.post(
        `/productions/batches/${batchId}/tasks/`,
        payload,
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      console.log(resp)
      this.tasks = []
      this.addQuantity = 0
      this.displayPlanificationForm = false
      this.init()
    },
    async deleteBatch(batchId) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.delete(`/productions/batches/${batchId}/`, {
        headers: {
          'X-Fields': 'id',
          'X-CSRF-TOKEN': respCsrf.data.csrf,
        },
      })
      this.init()
    },
    toggleSelectTasks() {
      if (this.selectAll === false) {
        this.tasks = []
      } else {
        this.tasks = []
        this.productions_tasks.forEach((task) => {
          if (task.production_batch_id === null) {
            this.tasks.push(task.id)
          }
        })
      }
    },
    getPeriod(period) {
      let week
      if (period) {
        const weeks = this.constants.WEEKS.filter((w) => w.start_at === period)
        if (weeks.length === 1) {
          return weeks[0]
        }
      }
      return week
    },
  },
}
</script>

<style></style>
